import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { FallbackComponent } from './src/components/FallbackModal/FallbackComponent';
import { AuthContextProvider } from './src/contexts/AuthContext';

export const wrapRootElement = ({ element }) => {
  const handleError = async (error, { componentStack }) => {
    if (process.env.NODE_ENV === 'production') {
      try {
        await fetch(`${process.env.GATSBY_API_URL}/gitlab-issues`, {
          method: 'POST',
          body: JSON.stringify({
            error: { title: error?.message, description: error.toString() },
            path: window.location.href,
            stack: componentStack
          }),
          headers: new Headers({ 'Content-type': 'application/json' })
        });
      } catch (e) {
        console.error("Impossible de signaler l'erreur");
      }
    }
  };

  return (
    <ErrorBoundary FallbackComponent={FallbackComponent} onError={handleError}>
      <AuthContextProvider>{element}</AuthContextProvider>
    </ErrorBoundary>
  );
};
